// export const logo = 'static/img/fyoonacom/svg/logo-no-background.svg'

// export const logo2 = 'static/img/fyoonacom/svg/logo-no-background.svg'
export const footerimg = 'static/img/fyoonacom/footer-payment.webp'

export var file = 'static/3d/realistic_human_cloths.glb'
export const logo = 'static/img/fyoonacom/logo.jpeg'

export const logo2 = 'static/img/fyoonacom/logo.jpeg'

// export const bg1 = 'static/img/fyoonacom/home/banner-2.jpg'
// export const bg1 = 'static/vids/Desktop_Homepage_16x9.mp4'
export const bg1 = 'https://res.cloudinary.com/dgysnizyn/video/upload/v1702378095/productsvid/vxltm59xtevkvotrnfgf.mov'

// export const bg1 = [
//    {
//     image_Url:'static/img/fyoonacom/home/banner-2.jpg',
//    }

//     export const bg1 = 'static/img/fyoonacom/home/banner-2.jpg'
// export const bg3 = 'static/img/fyoonacom/home/banner-2.jpg'
// export const bg4 = 'static/img/fyoonacom/home/banner-2.jpg'


// ]
export const maincolor = '#f57f16 !important';

