import React from 'react';

import VendorBanner from '../components/partials/vendor/VendorBanner';
import VendorAbout from '../components/partials/vendor/VendorAbout';
import VendorMileStone from '../components/partials/vendor/VendorMileStone';
import VendorBestFees from '../components/partials/vendor/VendorBestFees';
import VendorTestimonials from '../components/partials/vendor/VendorTestimonials';
import VendorFaqs from '../components/partials/vendor/VendorFaqs';
import Header from "../components/Layout/Header";
import Footer from '../components/Layout/Footer';
const BecomeSeller = () => {
   
    return (
        <div>
           
            <Header activeHeading={1} />
                <VendorBanner />
                {/* <VendorAbout />
                <VendorMileStone />
                <VendorBestFees />
                <VendorTestimonials />
                <VendorFaqs /> */}
                <Footer />
        </div>
      )
   
};

export default BecomeSeller;
