import React from 'react';
import { Link } from "react-router-dom";
import styles from '../../../styles/styles';
const VendorBanner = () => (
    <>
    <div
        className="ps-vendor-banner bg--cover"
        style={{ backgroundImage: "url('/static/img/bg/vendor.jpg')" }}
    >
        <div className="ps-vendor-banner">
            <div className="container">
                <h2>
                    Millions Of Shoppers Can’t Wait To See What You Have In
                    Store
                </h2>
             <h1 className="ps-btn ps-btn--xlg items-center"> 
             <div className={`${styles.button}`}>
            <Link to={"/shop-create"}>
              <h6 className="text-[#fff] flex items-center">
                         Create Account
              </h6>
            </Link>
                 </div>     
              </h1> 
             
            </div>
        </div>
    </div>


{/* ///about */}
<div className="ps-section--vendor ps-vendor-about">
<div className="container">
    <div className="ps-section__header">
        <p>WHY SELL ON Fyoona</p>
        <h4>
            Join a marketplace where nearly 50 million buyers around <br /> the world shop
            for unique items
        </h4>
    </div>
    <div className="ps-section__content">
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                <div className="ps-block--icon-box-2">
                    <div className="ps-block__thumbnail">
                        <img src="/static/img/icons/vendor-1.png" alt="Fyoona" />
                    </div>
                    <div className="ps-block__content">
                        <h4>Low Fees</h4>
                        <div className="ps-block__desc" data-mh="about-desc">
                            <p>
                                It doesn’t take much to list your items and once you make a
                                sale, Fyoona’s transaction fee is just 2.5%.
                            </p>
                        </div>
                        <Link to={ "/#"}>
      Learn more
    </Link>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                <div className="ps-block--icon-box-2">
                    <div className="ps-block__thumbnail">
                        <img src="/static/img/icons/vendor-2.png" alt="Fyoona" />
                    </div>
                    <div className="ps-block__content">
                        <h4>Powerful Tools</h4>
                        <div className="ps-block__desc" data-mh="about-desc">
                            <p>
                                Our tools and services make it easy to manage, promote and
                                grow your business.
                            </p>
                        </div>
                        <Link to={ "/#"}>
      {/* <h1 className="text-[#fff] flex items-center"> */}
      Learn more
      {/* </h1> */}
    </Link>                            </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                <div className="ps-block--icon-box-2">
                    <div className="ps-block__thumbnail">
                        <img src="/static/img/icons/vendor-3.png" alt="Fyoona" />
                    </div>
                    <div className="ps-block__content">
                        <h4>Support 24/7</h4>
                        <div className="ps-block__desc" data-mh="about-desc">
                            <p>
                                Our tools and services make it easy to manage, promote and
                                grow your business.
                            </p>
                        </div>
                        <Link to={ "/#"}>
      {/* <h1 className="text-[#fff] flex items-center"> */}
      Learn more
      {/* </h1> */}
    </Link>                            </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

{/* milstone */}
<div className="ps-section--vendor ps-vendor-milestone">
        <div className="container">
            <div className="ps-section__header">
                <p>How it works</p>
                <h4>
                    Easy to start selling online on Fyoona just 4 simple steps
                </h4>
            </div>
            <div className="ps-section__content">
                <div className="ps-block--vendor-milestone">
                    <div className="ps-block__left">
                        <h4>Register and list your products</h4>
                        <ul>
                            <li>
                                Register your business for free and create a
                                product catalogue. Get free training on how to
                                run your online business
                            </li>
                            <li>
                                Our Fyoona Advisors will help you at every
                                step and fully assist you in taking your
                                business online
                            </li>
                        </ul>
                    </div>
                    <div className="ps-block__right">
                        <img src="/static/img/vendor/milestone-1.png" alt="Fyoona" />
                    </div>
                    <div className="ps-block__number">
                        <span>1</span>
                    </div>
                </div>
                <div className="ps-block--vendor-milestone reverse">
                    <div className="ps-block__left">
                        <h4>Receive orders and sell your product</h4>
                        <ul>
                            <li>
                                Register your business for free and create a
                                product catalogue. Get free training on how to
                                run your online business
                            </li>
                            <li>
                                Our Fyoona Advisors will help you at every
                                step and fully assist you in taking your
                                business online
                            </li>
                        </ul>
                    </div>
                    <div className="ps-block__right">
                        <img src="/static/img/vendor/milestone-2.png" alt="Fyoona" />
                    </div>
                    <div className="ps-block__number">
                        <span>2</span>
                    </div>
                </div>
                <div className="ps-block--vendor-milestone">
                    <div className="ps-block__left">
                        <h4>Package and ship with ease</h4>
                        <ul>
                            <li>
                                Register your business for free and create a
                                product catalogue. Get free training on how to
                                run your online business
                            </li>
                            <li>
                                Our Fyoona Advisors will help you at every
                                step and fully assist you in taking your
                                business online
                            </li>
                        </ul>
                    </div>
                    <div className="ps-block__right">
                        <img src="/static/img/vendor/milestone-3.png" alt="Fyoona" />
                    </div>
                    <div className="ps-block__number">
                        <span>3</span>
                    </div>
                </div>
                <div className="ps-block--vendor-milestone reverse">
                    <div className="ps-block__left">
                        <h4>Package and ship with ease</h4>
                        <ul>
                            <li>
                                Register your business for free and create a
                                product catalogue. Get free training on how to
                                run your online business
                            </li>
                            <li>
                                Our Fyoona Advisors will help you at every
                                step and fully assist you in taking your
                                business online
                            </li>
                        </ul>
                    </div>
                    <div className="ps-block__right">
                        <img src="/static/img/vendor/milestone-4.png" alt="Fyoona" />
                    </div>
                    <div className="ps-block__number">
                        <span>4</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* //best fee */}
    <div className="ps-section--vendor ps-vendor-best-fees">
        <div className="container">
            <div className="ps-section__header">
                <p>BEST FEES TO START</p>
                <h4>Affordable, transparent, and secure</h4>
            </div>
            <div className="ps-section__content">
                <h5>
                    It doesn’t cost a thing to list up to 50 items a month, and you only pay after
                    your stuff sells. It’s just a small percent of the money you earn
                </h5>
                <div className="ps-section__numbers">
                    <figure>
                        <h3>$0</h3>
                        <span>List Fee</span>
                    </figure>
                    <figure>
                        <h3>5%</h3>
                        <span>Final Value Fee</span>
                    </figure>
                </div>
                <div className="ps-section__desc">
                    <figure>
                        <figcaption>Here's what you get for your fee:</figcaption>
                        <ul>
                            <li>A worldwide community of more than 160 million shoppers.</li>
                            <li>
                                Shipping labels you can print at home, with big discounts on
                                postage.
                            </li>
                            <li>
                                Seller protection and customer support to help you sell your stuff.
                            </li>
                        </ul>
                    </figure>
                </div>
                <div className="ps-section__highlight">
                    <img src="/static/img/icons/vendor-4.png" alt="Fyoona" />
                    <figure>
                        <p>
                            We process payments with PayPal, an external payments platform that
                            allows you to process transactions with a variety of payment methods.
                            Funds from PayPal sales on Fyoona will be deposited into your PayPal
                            account.
                        </p>
                    </figure>
                </div>
                <div className="ps-section__footer">
                    <p>
                        Listing fees are billed for 0.20 USD, so if your bank’s currency is not USD,
                        the amount in your currency may vary based on changes in the exchange rate.
                    </p>
                </div>
            </div>
        </div>
    </div>


    {/* faq */}
    <div className="ps-section--vendor ps-vendor-faqs">
        <div className="container">
            <div className="ps-section__header">
                <p>FREQUENTLY ASKED QUESTIONS</p>
                <h4>Here are some common questions about selling on Fyoona</h4>
            </div>
            <div className="ps-section__content">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <figure>
                            <figcaption>How do fees work on Fyoona?</figcaption>
                            <p>
                                Joining and starting a shop on Fyoona is free. There are three
                                basic selling fees: a listing fee, a transaction fee, and a payment
                                processing fee.
                            </p>
                            <p>
                                It costs USD 0.20 to publish a listing to the marketplace. A listing
                                lasts for four months or until the item is sold. Once an item sells,
                                there is a 3.5% transaction fee on the sale price (not including
                                shipping costs). If you accept payments with PayPal, there is also a
                                payment processing fee based on their fee structure.
                            </p>
                            <p>
                                Listing fees are billed for $0.20 USD, so if your bank’s currency is
                                not USD, the amount may differ based on changes in the exchange
                                rate.
                            </p>
                        </figure>
                        <figure>
                            <figcaption>What do I need to do to create a shop?</figcaption>
                            <p>
                                It’s easy to set up a shop on Fyoona. Create an Fyoona account
                                (if you don’t already have one), set your shop location and
                                currency, choose a shop name, create a listing, set a payment method
                                (how you want to be paid), and finally set a billing method (how you
                                want to pay your Fyoonafees).
                            </p>
                        </figure>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <figure>
                            <figcaption>How do I get paid?</figcaption>
                            <p>
                                If you accept payments with PayPal, funds from PayPal sales on
                                Fyoona will be deposited into your PayPal account. We encourage
                                sellers to use a PayPal Business account and not a Personal account,
                                as personal accounts are subject to monthly receiving limits and
                                cannot accept payments from buyers that are funded by a credit card.
                            </p>
                            <p>
                                It costs USD 0.20 to publish a listing to the marketplace. A listing
                                lasts for four months or until the item is sold. Once an item sells,
                                there is a 3.5% transaction fee on the sale price (not including
                                shipping costs). If you accept payments with PayPal, there is also a
                                payment processing fee based on their fee structure.
                            </p>
                            <p>
                                Listing fees are billed for $0.20 USD, so if your bank’s currency is
                                not USD, the amount may differ based on changes in the exchange
                                rate.
                            </p>
                        </figure>
                        <figure>
                            <figcaption>
                                Do I need a credit or debit card to create a shop?
                            </figcaption>
                            <p>
                                No, a credit or debit card is not required to create a shop. To be
                                verified as a seller you have the choice to use either a credit card
                                or to register via PayPal. You will not incur any charges until you
                                open your shop and publish your listings.
                            </p>
                        </figure>
                        <figure>
                            <figcaption>What can I sell on Fyoona?</figcaption>
                        </figure>
                        <p>
                            Fyoona provides a marketplace for crafters, artists and collectors to
                            sell their handmade creations, vintage goods (at least 20 years old),
                            and both handmade and non-handmade crafting supplies.
                        </p>
                    </div>
                </div>
            </div>
            <div className="ps-section__footer">
                <p>Still have more questions? Feel free to contact us.</p>
                <Link to={ "/#"} className="ps-btn" href="#">
              Contact Us             
            </Link>
                    
            </div>
        </div>
    </div>
</>


);

export default VendorBanner;
