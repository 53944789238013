import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { bg1 } from "../../../logo"; // Assuming bg1 is the video file

const Hero = () => {
  return (
    <div className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full ${styles.noramlFlex}`} style={{ position: 'relative' }}>
      <video autoPlay muted loop className="absolute inset-0 w-full h-full object-cover">
        <source src={bg1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`} style={{ position: 'relative', zIndex: 1 }}>
        <h1 className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}>
          Best Collection for <br />  Fashion
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          Elevate your style with our curated collection of the latest fashion trends.<br />
          From casual chic to red carpet glamour, we have everything you need to express your unique personality and make a statement wherever you go.
        </p>
        <Link to="/products" className="inline-block">
          <div className={`${styles.button} mt-5`}>
            <span className="text-[#fff] font-[Poppins] text-[18px]">
              Shop Now
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
